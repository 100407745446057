export const slitText = ($el) => {
  const words = $el.innerHTML.trim().split(" ")

  $el.innerHTML = words.map((word) => {
    let isLetterTag = false

    const letters = word.split("").map(letter => {
      if (letter === "<") {
        isLetterTag = true
      }

      if (letter === ">") {
        isLetterTag = false
      }

      return !isLetterTag ? `<span class="letter">${letter}</span>` : letter
    }).join("")

    return !word.includes("<") && !word.includes(">") ? `<span class="word">${letters}</span>` : word
  }).join(" ")
}

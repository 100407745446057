import { button } from "./ui/button"
import { select } from "./components/select"
// import { accordion } from "./components/accordion"
// import { formValidation } from "./components/form-validation"
// import { modal } from "./components/modal"
// import { tabs } from "./components/tabs"
import { navigation } from "./modules/navigaton"
import { header } from "./modules/header"
import { heroSearchFormField } from "./components/hero-search-form-field"
import { swiper } from "./components/swiper"
import Swiper from "swiper"
import { EffectFade, Navigation, Pagination, Thumbs } from "swiper/modules"
import { heroAnimation } from "./modules/hero-section"
import { titles } from "./animation/titles"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { fadeUpAnimation } from "./animation/fade-up"
import { fadeInBlocks } from "./animation/fade-in-blocks"
import { textAnimation } from "./animation/text-animation"
import { browserDetect } from "./utils/browser-detect"
import { buttonSlider } from "./ui/button-slider"
import { inspiredCollection } from "./modules/inspired-collection"

gsap.registerPlugin(ScrollTrigger)
Swiper.use([Pagination, Navigation, Thumbs, EffectFade])

window.addEventListener("load", () => {
  window.browserClient = browserDetect()
  const $loader = document.querySelector(".loader")

  // eslint-disable-next-line no-undef
  SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime: 1300,
    // Размер шага в пикселях
    stepSize: 50,
    // Ускорение
    accelerationDelta: 20,
    // Максимальное ускорение
    accelerationMax: 2,
    // Поддержка клавиатуры
    keyboardSupport: true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll: 90,
    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,
    // Поддержка тачпада
    touchpadSupport: false
  })

  // Show loader animation
  $loader && $loader.classList.add("active")

  // Show hidden elements
  document.querySelectorAll(".important_none")?.forEach(($el) => $el.classList.remove("important_none"))

  // ===== UI
  button()

  buttonSlider()

  // ===== Components
  // All selects
  select()

  // Hero Search Form Fields
  heroSearchFormField()

  // Swiper init
  swiper()

  // All accordions
  // const accordionsInstances = accordion()

  // Tabs
  // const tabsInstance = tabs()

  // Forms
  // const formsInstances = formValidation()

  // Modals
  // const modalsInstance = modal()
  // ===== Components end

  // ===== Modules
  // Nav
  navigation()

  // Header
  header()

  // Inspired collection
  inspiredCollection()
  // ===== Modules end

  // ===== Animation gsap
  // All titles
  titles()

  // All fade up elements
  fadeUpAnimation()

  // All fade in elements
  fadeInBlocks()

  // All text elements
  textAnimation()

  // Hero section
  // ===== Animation gsap end

  // Hide loader
  if ($loader) {
    setTimeout(() => {
      $loader.classList.add("hide")

      setTimeout(() => {
        heroAnimation()
        document.body.style.overflow = "initial"
      }, 1000)
    }, 2000)
  }
})

import { slitText } from "../utils/slit-text"
import gsap from "gsap"

export const textAnimation = () => {
  const textItems = document.querySelectorAll(".js-text-item")
  if (!textItems.length) return

  const animate = ($text) => {
    slitText($text)

    const words = $text.querySelectorAll(".word")

    gsap.fromTo(words, { opacity: 0 }, {
      opacity: 1,
      duration: 1,
      stagger: .05,
      delay: .05,
      scrollTrigger: {
        trigger: $text
      }
    })
  }

  textItems.forEach($text => {
    animate($text)
  })
}

import gsap from "gsap"

export const buttonSlider = () => {
  const buttons = document.querySelectorAll(".js-slider-btn")
  if (!buttons.length) return

  // === Methods
  const setBgPosition = ($circle, layerX) => {
    gsap.to($circle, {
      left: layerX
    })
    // $circle.style.left = `${layerX}px`
  }

  const onMouseMoveHandle = (event, $circle) => {
    if (window.innerWidth <= 1024) return
    const { pageX, currentTarget } = event
    const currentTargetRect = currentTarget.getBoundingClientRect()

    setBgPosition($circle, pageX - currentTargetRect.left)
  }

  const init = ($btn) => {
    const $circle = $btn.querySelector(".js-slider-btn-circle")

    $btn.addEventListener("mousemove", () => onMouseMoveHandle(event, $circle))
    $btn.addEventListener("mouseleave", () => {
      gsap.to($circle, {
        left: "50%"
      })
    })
  }

  // === Init
  buttons.forEach(init)
}

import gsap from "gsap"
import { slitText } from "../utils/slit-text"

export const heroAnimation = () => {
  const $section = document.querySelector(".hero")
  if (!$section) return

  // === System variables
  const bgMoveCoef = -.01
  const bgScaleCoef = 1.1

  // === Elements
  const $sectionBg = $section.querySelectorAll(".hero__bg")
  const $title = $section.querySelector(".hero__title")
  const formCols = $section.querySelectorAll(".hero__search-form-col")

  // === Methods

  const animateSearchFrom = () => {
    gsap.to(formCols, { opacity: 1, duration: 1, stagger: .2 })
  }

  const animate = () => {
    slitText($title)

    const letters = $title.querySelectorAll(".word")

    gsap.fromTo(letters, { opacity: 0 }, {
      opacity: 1, duration: 2, stagger: .2, delay: .2
    })

    setTimeout(() => {
      animateSearchFrom()
    }, 1500)
  }

  const onScrollHandle = () => {
    if (window.scrollY > $section.offsetHeight) {
      return
    }

    if (window.innerWidth <= 1024) {
      gsap.to($sectionBg, {
        y: 0,
        duration: 0
      })
      return
    }

    gsap.to($sectionBg, {
      y: window.scrollY * -.25,
      duration: 0
    })
  }

  // const onMouseMoveHandle = (event) => {
  //   if (window.innerWidth <= 1024) return
  //   const { clientY, clientX } = event
  //   const centerOffSectionY = $section.offsetHeight / 2
  //   const centerOffSectionX = $section.offsetWidth / 2
  //
  //   gsap.to($sectionBg, {
  //     x: (clientX - centerOffSectionX) * bgMoveCoef,
  //     y: (clientY - centerOffSectionY) * bgMoveCoef,
  //     scale: bgScaleCoef
  //   })
  // }
  //
  // const onMouseLEaveHandle = () => {
  //   if (window.innerWidth <= 1024) return
  //   gsap.to($sectionBg, {
  //     x: 0,
  //     y: 0,
  //     scale: bgScaleCoef
  //   })
  // }

  // === Init
  animate()

  // === Listeners
  // Scroll
  window.addEventListener("scroll", onScrollHandle)

  // Mouse move
  // $sectionBg && $section.addEventListener("mousemove", onMouseMoveHandle)
  // $sectionBg && $section.addEventListener("mouseleave", onMouseLEaveHandle)
}

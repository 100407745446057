export const heroSearchFormField = () => {
  const fields = document.querySelectorAll(".js-hero-search-form-field")
  if (!fields.length) return

  // === Methods
  const init = ($field) => {
    const $label = $field.querySelector(".js-hero-search-form-field-name")
    const $input = $field.querySelector(".js-hero-search-form-field-input")

    $input.addEventListener("focus", () => {
      $label.classList.add("_move-up")
    })

    $input.addEventListener("blur", () => {
      if ($input.value === "") {
        $label.classList.remove("_move-up")
      }
    })
  }

  // === Init
  fields.forEach(init)
}

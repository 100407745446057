export const button = () => {
  const buttons = document.querySelectorAll(".js-button")
  if (!buttons) return

  // === Methods
  const setBgPosition = ($btn, $bg, layerX = 0, layerY = 0) => {
    $bg.style.left = `${layerX}px`
    $bg.style.top = `${layerY}px`
  }

  const onMouseEnterHandle = (event, buttonBgRef) => {
    if (window.innerWidth <= 1024) return

    const { layerX, layerY, currentTarget } = event

    currentTarget.ontransitionend = null
    setBgPosition(currentTarget, buttonBgRef, layerX, layerY)
  }

  const onMouseLeaveHandle = (event, buttonBgRef) => {
    if (window.innerWidth <= 1024) return

    const { currentTarget } = event

    currentTarget.ontransitionend = () => {
      setBgPosition(currentTarget, buttonBgRef)

      currentTarget.ontransitionend = null
    }
  }

  const init = ($btn) => {
    const buttonBgRef = $btn.querySelector(".js-button-bg")
    if (!buttonBgRef) return

    $btn.onmouseenter = (event) => onMouseEnterHandle(event, buttonBgRef)
    $btn.onmouseleave = (event) => onMouseLeaveHandle(event, buttonBgRef)
  }

  // === Init
  buttons.forEach(init)
}

const header = () => {
  const $header = document.querySelector(".header")
  if (!$header) return {}

  // ===== System variables
  let currentScrollPosition = 0

  // ===== Methods
  function setCssVars() {
    document.documentElement.style.setProperty("--header-height", `${$header.offsetHeight}px`)
  }

  function scrollHandle() {
    if (window.innerWidth <= 1200) {
      $header.classList.remove("hide")
      return
    }

    if (window.scrollY < currentScrollPosition || window.scrollY < $header.offsetHeight) {
      $header.classList.remove("hide")
    } else {
      $header.classList.add("hide")
    }

    currentScrollPosition = window.scrollY
  }

  function mouseMoveHandle(event) {
    if (window.innerWidth <= 1200) {
      $header.classList.remove("hide")
      return
    }
    
    const { clientY } = event

    if (clientY < $header.offsetHeight) {
      $header.classList.remove("hide")
    }
  }

  // ===== Init
  setCssVars()
  scrollHandle()

  // === Listeners
  // Scroll
  window.addEventListener("scroll", (event) => {
    scrollHandle()
  })

  // Resize
  window.addEventListener("resize", (event) => {
    setCssVars()
  })

  // Resize
  window.addEventListener("mousemove", mouseMoveHandle)

  return {
    $header,
    scrollHandle,
    setCssVars
  }
}

export { header }

import gsap from "gsap"

export const fadeUpAnimation = () => {
  const blocks = document.querySelectorAll(".js-fade-up-block")
  if (!blocks.length) return

  // === Init
  blocks.forEach($block => {
    const items = $block.querySelectorAll(".js-fade-up-item")

    gsap.fromTo(items, { y: "100%" }, {
      y: 0,
      duration: 1,
      stagger: .2,
      delay: .2,
      scrollTrigger: {
        trigger: $block,
        start: "top bottom"
      },
      // onComplete: () => {
      //   const borderBg = $block.querySelectorAll(".bg-gold")
      //
      //   borderBg.length && borderBg.forEach($item => {
      //     $item.classList.add("hide-border")
      //   })
      // }
    })
  })
}

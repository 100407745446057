import Swiper from "swiper"

export const inspiredCollection = () => {
  const $section = document.querySelector(".inspired-collection")
  if (!$section) return

  // === Elements
  // const $sliderBtnPrev = $section.querySelector(".inspired-collection__descr-slider-button._prev")
  // const $sliderBtnNext = $section.querySelector(".inspired-collection__descr-slider-button._next")

  // === Init
  const inspiredCollectionImageSlider = new Swiper(".inspired-collection__image-slider .slider-container", {
    speed: 500,
    effect: "fade",
    allowTouchMove: false
  })

  new Swiper(".inspired-collection__descr-slider .slider-container", {
    speed: 500,
    effect: "fade",
    allowTouchMove: false,
    thumbs: {
      swiper: inspiredCollectionImageSlider
    },
    navigation: {
      prevEl: ".inspired-collection__descr-slider-button._prev",
      nextEl: ".inspired-collection__descr-slider-button._next"
    }
  })
}

import Swiper from "swiper"

export const swiper = () => {


  const $eventsSliderContainer = document.querySelector(".events__slider .slider-container")

  new Swiper($eventsSliderContainer, {
    speed: 750,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
      769: {
        slidesPerView: 1.35
      },
      1025: {
        slidesPerView: 1.55
      }
    },
    navigation: {
      prevEl: ".events__slider-btn.prev",
      nextEl: ".events__slider-btn.next"
    },
    pagination: {
      el: ".events__slider",
      renderBullet: (index, className) => {
        const paginationData = $eventsSliderContainer.dataset?.boolets
        if (!paginationData) return

        const bulletItem = JSON.parse($eventsSliderContainer.dataset?.boolets)[index]

        return `<span class="${className} ${index === 0 ? "swiper-pagination-bullet-active" : ""}">
          <span class="${className}-circle"></span>
          <span class="${className}-value">${bulletItem}</span>
        </span>`
      }
    }
  })

  new Swiper(".testimonials__slider .slider-container", {
    speed: 500,
    slidesPerView: 1,
    spaceBetween: 40,
    navigation: {
      prevEl: ".testimonials__slider-btn._prev",
      nextEl: ".testimonials__slider-btn._next"
    }
  })

  new Swiper(".partners__slider .slider-container", {
    loop: true,
    slidesPerView: 1.75,
    spaceBetween: 20,
    breakpoints: {
      376: {
        slidesPerView: 2.5,
      },
      577: {
        slidesPerView: 3.5,
      },
      769: {
        slidesPerView: 4.5,
      },
      1025: {
        slidesPerView: 5,
      }
    }
  })
}


